<template>
	<div>
		<div class="mh100 bg-white boderra8 box-shadow0010">
			<div class="padd30 ">
				<ClassSelect :list="list"></ClassSelect>
				<loadMore :state="state" loading_icon></loadMore>
			</div>
		</div>
		
	<div class="center paddt54 paddb15">
		<el-pagination background  class="center "  @current-change="handleCurrentChange"
		    layout="prev, pager, next, jumper" :current-page="page"   :total="maxnum" :page-size="size">
		</el-pagination>
	</div>
	</div>
</template>

<script>
	export default {
		name: 'MyClass',
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				size: 10,
				maxnum:'' ,//总条数
			}
		},
		mounted() {
			this.getData()
		
		},
		methods: {	
			getData() {
				if (this.loading || this.state > 1) return;
				let page = parseInt(this.page) + 1;
				this.loading = true;
				this.state = 1;
				let size = 10;
				this.$req({
					method: 'get',
					data: {
						type: 'course',
						page: this.page,
						size: size,
					},
					url: '/api/member/course',
					success: res => {
						let list = res;
						console.log(list)
						this.list = list;
						if (list.length == size) {
							this.state = 1;
						} else if (list.length > 0) {
							this.state = 2;
						} else if (this.list.length == 0) {
							this.state = 3;
						}
						this.loading = false;
					},
					fail: error => {
			
					}
				});
				this.$req({
					method: 'get',
					data: {
						 type: 'course',
						show_page:1,
						
					},
					url: '/api/member/course',
					success: res => {
						console.log(res,'总条数')
						this.maxnum =res;
					},
					fail: error => {
						
					}
				});
			},
			handleCurrentChange(val) {
				   this.page = val;
				   this.list = [];
				   this.loading = false;
				   this.getData()
				    document.documentElement.scrollTop = document.body.scrollTop = 400;
				
			}
			
		}
	}
</script>

<style lang="less">
	.class-item {
		width: 283px;
		background: #FFFFFF;
		border: 1px solid #E6E6E6;
		border-radius: 8px;
		
		.class-item-pic {
			width: 285px;
			height: 220px;
		}
		
		.clas-name {
			max-height: 58px;
		}
	}
	
	.class-item:nth-child(3n) {
		margin-right: 0;
	}
	
	.class-item:hover {
		cursor: pointer;
		border: 1px solid #DCFF03;
	}
	
	.class-item:hover .clas-name {
		color: #010085;
	}
	
	// 改变组件样式
	.el-pager li {
		font-size: 14px !important;
		width: 40px !important;
		height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
		line-height: 40px !important;
		padding: 0 !important;
		color: #999999!important;
	}
	
	.btn-prev, .btn-next {
		width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
		border-radius: 4px !important;
		border: 1px solid #CCCCCC !important;
	}
	
	.el-pager .active {
		background: #DCFF03 !important;
		color: #010085 !important;
		border: 1px solid #DCFF03 !important;
	}
</style>
